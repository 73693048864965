/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'
import { LocalParticipant, Participant, Track } from 'livekit-client'
import {
  ParticipantClickEvent,
  TrackReferenceOrPlaceholder,
  isTrackReference,
  isTrackReferencePinned,
} from '@livekit/components-core'
import {
  FocusToggle,
  ParticipantContext,
  TrackMutedIndicator,
  TrackRefContext,
  VideoTrack,
  useEnsureParticipant,
  useEnsureTrackRef,
  useFeatureContext,
  useMaybeLayoutContext,
  useMaybeParticipantContext,
  useMaybeTrackRefContext,
  useParticipantTile,
} from '@livekit/components-react'
import {
  useGetUserPosition,
  useHandRaiseReceivedHook,
  useIsPinnedTrackHook,
  useParticipantTilePosition,
} from 'livekit/livekitHooks'
import { UserType } from 'livekit/livekitTypes'
import { ToolTip } from 'base/tooltip/tooltip'
import {
  faThumbTack,
  faSlash,
  faHand,
  faMicrophone,
  faMicrophoneSlash,
  faCompress,
  faExpand,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomParticipantPlaceholder } from './CustomParticipantPlaceholder'
import { useCustomGridContext } from './CustomGridContext'
import { useCustomRoomContext } from './CustomRoomContext'
import { useTranslation } from 'react-i18next'
import { Transition, Dialog } from '@headlessui/react'

/**
 * A React component that conditionally renders a `ParticipantContext.Provider` if there is no existing `ParticipantContext`.
 *
 * @param {React.PropsWithChildren<{participant?: Participant}>} props - The component props.
 * @param {Participant | undefined} props.participant - The participant to be rendered in the context.
 * @return {JSX.Element} The rendered component.
 */

export function ParticipantContextIfNeeded(
  props: React.PropsWithChildren<{
    participant?: Participant
  }>
) {
  const hasContext = !!useMaybeParticipantContext()
  return props.participant && !hasContext ? (
    <ParticipantContext.Provider value={props.participant}>{props.children}</ParticipantContext.Provider>
  ) : (
    <>{props.children}</>
  )
}

/**
 * Renders the children wrapped in a TrackRefContext.Provider if a track reference is provided and the context is not available.
 *
 * @param {React.PropsWithChildren<{
 *    trackRef?: TrackReferenceOrPlaceholder
 * }>} props - The component props.
 * @param {TrackReferenceOrPlaceholder | undefined} props.trackRef - The track reference or placeholder.
 * @return {JSX.Element} The rendered component.
 */

function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder
  }>
) {
  const hasContext = !!useMaybeTrackRefContext()
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  )
}

/** @public */
export interface ParticipantTileProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The track reference to display. */
  trackRef?: TrackReferenceOrPlaceholder
  disableSpeakingIndicator?: boolean

  onParticipantClick?: (event: ParticipantClickEvent) => void
}

/**
 * The `ParticipantTile` component is the base utility wrapper for displaying a visual representation of a participant.
 * This component can be used as a child of the `TrackLoop` component or by passing a track reference as property.
 *
 * @example Using the `ParticipantTile` component with a track reference:
 * ```tsx
 * <ParticipantTile trackRef={trackRef} />
 * ```
 * @example Using the `ParticipantTile` component as a child of the `TrackLoop` component:
 * ```tsx
 * <TrackLoop>
 *  <ParticipantTile />
 * </TrackLoop>
 * ```
 * @public
 */

export function CustomParticipantTile({
  trackRef,
  children,
  onParticipantClick,
  disableSpeakingIndicator,
  ...htmlProps
}: ParticipantTileProps) {
  const trackReference = useEnsureTrackRef(trackRef)

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    htmlProps,
    disableSpeakingIndicator,
    onParticipantClick,
    trackRef: trackReference,
  })
  const layoutContext = useMaybeLayoutContext()
  const { isHiddenParticipant, userPosition, fullName, roundSize } = useParticipantTilePosition(trackReference)
  const participant = useEnsureParticipant()
  const isPinned = useIsPinnedTrackHook(trackReference)
  const { addPinnedParticipant, removePinnedParticipant } = useCustomGridContext()
  const { pinnedParticipantCount, unpinnedParticipantCount } = useGetUserPosition(trackReference)
  const metadata = React.useMemo(
    () => (trackReference.participant.metadata ? JSON.parse(trackReference.participant.metadata) : {}),
    [trackReference.participant.metadata]
  )

  const autoManageSubscription = useFeatureContext()?.autoSubscription
  const p = useEnsureParticipant()
  const { handRaisedParticipants, unmuteCurrentOnly } = useHandRaiseReceivedHook()
  const { stagedParticipants, viewType } = useCustomRoomContext()
  const { t } = useTranslation()

  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const elementRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  const toggleFullScreen = () => {
    if (!isFullscreen) {
      if (elementRef.current?.requestFullscreen) {
        elementRef.current.requestFullscreen()
      } else if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  const handleSubscribe = React.useCallback(
    (subscribed: boolean) => {
      if (
        trackReference.source &&
        !subscribed &&
        layoutContext &&
        layoutContext.pin.dispatch &&
        isTrackReferencePinned(trackReference, layoutContext.pin.state)
      ) {
        layoutContext.pin.dispatch({ msg: 'clear_pin' })
      }
    },
    [trackReference, layoutContext]
  )

  // Use useEffect to apply styles when the component mounts
  React.useEffect(() => {
    if (elementRef.current) {
      elementRef.current.style.top = `${userPosition.top}px`
      elementRef.current.style.left = `${userPosition.left}px`
      elementRef.current.style.width = `${userPosition.w}px`
      elementRef.current.style.height = `${userPosition.h}px`
    }
  }, [userPosition])

  if (isHiddenParticipant || !userPosition) {
    return <></>
  }

  function HandRaiseToggle({
    participantId,
    filteredHandRaisedParticipants,
    allowUnmute,
  }: {
    participantId: string
    filteredHandRaisedParticipants: string[]
    allowUnmute: () => void
  }) {
    const [isVisible, setIsVisible] = React.useState<boolean>(true)
    const [isOpen, setIsOpen] = React.useState(false)
    const { camEnabledUserCount } = useGetUserPosition(trackReference)
    const countForModel = camEnabledUserCount + stagedParticipants.length

    const handleClick = () => {
      allowUnmute()
      setIsVisible(false)
    }

    const openModal = () => {
      setIsOpen(true)
    }

    const closeModal = () => {
      setIsOpen(false)
    }

    if (!isVisible || !filteredHandRaisedParticipants.includes(participantId)) {
      return null
    }

    return (
      <>
        <div
          id={`icon_handRaise_${participant.sid.slice(0, -5)}`}
          className="absolute top-2 ease-in-out left-4 bg-white rounded-full shadow-md justify-center items-center p-1 text-sm text-black transition-all animate-waving-hand"
        >
          <FontAwesomeIcon icon={faHand} height={24} />
        </div>
        <div
          id={`btn_Unmute_${participant.sid.slice(0, -5)}`}
          className="absolute top-2 ease-in-out left-12 bg-white rounded-full shadow-md justify-center items-center p-1 text-sm text-black cursor-pointer z-10"
          onClick={countForModel > 0 ? openModal : handleClick}
        >
          {t('unmute')}
        </div>
        <Transition appear show={isOpen} as={React.Fragment}>
          <Dialog as="div" className="relative z-1000" onClose={openModal}>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed-bg-overlay" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center bg-transparent">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-semibold text-gray-900" id="confirmModal">
                      {t('If unmute, other participants will be muted. Continue ?')}
                    </Dialog.Title>
                    <div className="mt-6 flex flex-row items-center justify-end gap-x-3">
                      {
                        <button
                          id="btn_modalLeave"
                          type="button"
                          className="bg-gray-200 py-1 text-base uppercase hover:bg-red-400 font-semibold rounded px-2 text-black"
                          onClick={() => {
                            closeModal()
                          }}
                        >
                          {t('cancel')}
                        </button>
                      }
                      {
                        <button
                          id="btn_modalEnd"
                          type="button"
                          className="bg-gray-200 py-1 text-base uppercase hover:bg-amber-400 hover:text-white font-semibold rounded px-2 text-black"
                          onClick={() => {
                            closeModal()
                            handleClick()
                          }}
                        >
                          {t('ok')}
                        </button>
                      }
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }

  function filterStagedParticipants(handRaisedParticipants: any[], stagedParticipants: string | any[]) {
    return handRaisedParticipants.filter((id) => !stagedParticipants.includes(id) || id === participant.sid)
  }

  function ShowPinOption() {
    if (viewType === 'hostHighlighted' && !participant.isScreenShareEnabled && !participant.isCameraEnabled) {
      return <></>
    }

    const sidSource =
      trackReference?.source === Track.Source.ScreenShare
        ? trackReference.participant.sid + '-' + trackReference?.source
        : trackReference.participant.sid

    const textSizeClass =
      unpinnedParticipantCount > 12
        ? 'md:text-[10px] sm:text-[9px] xs:text-[8px]'
        : unpinnedParticipantCount > 10
        ? 'md:text-[15px] sm:text-[12px] xs:text-[10px]'
        : 'text-xs'

    return (
      <>
        {!isPinned ? (
          <div className="p-2">
            <ToolTip tip={t('pin')}>
              <div
                className="p-2 bg-slate-200 bg-opacity-50 hover:bg-opacity-100 rounded-full cursor-pointer"
                onClick={() => {
                  addPinnedParticipant([sidSource])
                }}
              >
                <FontAwesomeIcon icon={faThumbTack} className={`text-black lg:text-md md:text-sm ${textSizeClass}`} />
              </div>
            </ToolTip>
          </div>
        ) : (
          <div className="p-2 relative">
            <ToolTip tip={t('unpin')}>
              <div
                className="p-2 bg-slate-200 bg-opacity-50 hover:bg-opacity-100 rounded-full cursor-pointer"
                onClick={() => removePinnedParticipant(sidSource)}
              >
                <FontAwesomeIcon icon={faThumbTack} className={`text-black lg:text-md md:text-sm ${textSizeClass}`} />
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <FontAwesomeIcon icon={faSlash} className={`text-black lg:text-md md:text-sm z-1 ${textSizeClass}`} />
                </div>
              </div>
            </ToolTip>
          </div>
        )}
      </>
    )
  }

  function MicIndicator({ isMicEnabled, id }: { isMicEnabled: boolean; id?: string | number }) {
    return (
      <div className="w-full flex-row flex justify-end">
        {isMicEnabled ? (
          <FontAwesomeIcon icon={faMicrophone} id={`${id}_mic`} className="mr-1 text-gray-500" />
        ) : (
          <FontAwesomeIcon icon={faMicrophoneSlash} id={`${id}_micSlash`} className="text-gray-500" />
        )}
      </div>
    )
  }

  return (
    <div
      {...elementProps}
      ref={elementRef}
      className={`absolute rounded-md p-1 translate-x-0 transition-all group ${
        participant.isSpeaking && participant.isCameraEnabled ? 'outline outline-green-400 overflow-hidden' : ''
      }`}
    >
      <TrackRefContextIfNeeded trackRef={trackReference}>
        <ParticipantContextIfNeeded participant={trackReference.participant}>
          {children ?? (
            <>
              {isTrackReference(trackReference) &&
                trackReference.publication?.kind === 'video' &&
                (trackReference.source === Track.Source.Camera &&
                trackReference.publication?.isMuted === false &&
                trackReference.participant instanceof LocalParticipant ? (
                  <div className="transform scale-x-[-1]">
                    <VideoTrack
                      trackRef={trackReference}
                      onSubscriptionStatusChanged={handleSubscribe}
                      manageSubscription={autoManageSubscription}
                      className="relative aspect-video rounded-md"
                      onError={(error) => console.error('Video track error:', error)}
                    />
                  </div>
                ) : (
                  ((trackReference.source === Track.Source.Camera && trackReference.publication?.isMuted === false) ||
                    trackReference.source === Track.Source.ScreenShare) && (
                    <VideoTrack
                      trackRef={trackReference}
                      onSubscriptionStatusChanged={handleSubscribe}
                      manageSubscription={autoManageSubscription}
                      className="relative aspect-video rounded-md border border-gray-300"
                      onError={(error) => console.error('Video track error:', error)}
                    />
                  )
                ))}

              {((trackReference.publication?.kind === 'video' &&
                trackReference.source === Track.Source.Camera &&
                trackReference.publication?.isMuted === true) ||
                trackReference?.publication === undefined) && (
                <CustomParticipantPlaceholder
                  height={pinnedParticipantCount > 0 ? roundSize / 2.2 : roundSize / 1.6}
                  width={pinnedParticipantCount > 0 ? roundSize / 2.2 : roundSize / 1.6}
                />
              )}
              <div className="lk-participant-metadata">
                <div className="flex flex-row items-center p-1">
                  {trackReference.source === Track.Source.Camera ? (
                    <>
                      <TrackMutedIndicator
                        trackRef={trackReference}
                        show={'muted'}
                        className="m-2"
                        id={`icon_camIndicator-${participant.sid.slice(0, -5)}`}
                      ></TrackMutedIndicator>
                      {trackReference.publication?.kind === 'video' &&
                        trackReference.source === Track.Source.Camera &&
                        trackReference.publication?.isMuted === false && (
                          <div className="text-white pl-2">
                            {metadata.userType === UserType.HOST ? (
                              <div>
                                {fullName} ({t('host')})
                              </div>
                            ) : metadata.userType === UserType.CO_HOST ? (
                              <div>
                                {fullName} ({t('coHost')})
                              </div>
                            ) : (
                              <div>{fullName} </div>
                            )}
                          </div>
                        )}
                    </>
                  ) : (
                    <>{fullName}&apos;s screen</>
                  )}
                </div>
                <ShowPinOption />
              </div>
              <div className="absolute top-2 right-2 m-2">
                <MicIndicator isMicEnabled={participant.isMicrophoneEnabled} />
              </div>
            </>
          )}
          <FocusToggle trackRef={trackReference} />
        </ParticipantContextIfNeeded>
        <HandRaiseToggle
          participantId={p.sid}
          filteredHandRaisedParticipants={filterStagedParticipants(handRaisedParticipants, stagedParticipants)}
          allowUnmute={unmuteCurrentOnly}
        />

        <button
          className={`absolute bottom-1.5 text-xl ${
            viewType === 'hostHighlighted' && !participant.isScreenShareEnabled && !participant.isCameraEnabled
              ? 'right-2'
              : 'right-10'
          } text-gray-500 p-2 rounded transition-opacity opacity-0 group-hover:opacity-100`}
          onClick={toggleFullScreen}
        >
          {isFullscreen ? <FontAwesomeIcon icon={faCompress} /> : <FontAwesomeIcon icon={faExpand} />}
        </button>
      </TrackRefContextIfNeeded>
    </div>
  )
}
