import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../Components';
import { Modal, ModalCloseButton, ModalHeader, ModalBody } from '../../Components/base/modal/modal';
import Icon from '../../Components/base/icon/icon';
import { ToolTip } from '../../Components/base/tooltip/tooltip';
import Chip from '../../Components/Chip';
import { useCreateBlueTickRequestMutation, useUploadBlueTickAttachmentsMutation } from '../../Services/blueTickRequestApi';
import { useAppSelector } from '../../Store/hooks';
import { getCurrentUser } from '../../Services/userReducer';
import { BlueTickVerified } from '../../models/blueTickRequest.model';
import { t } from 'i18next';
import { toastSuccess } from '../../Components/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { settingsValidationSchema } from '../../Utils/validation';


export const BlueTickVerificationForm = () => {
  const [fileValue, setFileValue] = useState<File[]>([]);
  const [clearVisibility, setClearVisibility] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(settingsValidationSchema),
  });

  const [createRequest, { isLoading : requestLoading , isSuccess: requestSuccess}] = useCreateBlueTickRequestMutation();
  const [uploadAttachment, { isLoading : attachmentLoading , isSuccess: attachmentSuccess}] = useUploadBlueTickAttachmentsMutation();

  // File validation logic
  const validateFormate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) =>
        ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)
      );
      setFileValue(validFiles);

      if (validFiles.length !== files.length) {
        alert('Some files were skipped because they are not in PNG, JPEG, or JPG format.');
      }
    }
  };

  const removeFile = (fileName: string) => {
    setFileValue(fileValue.filter((file) => file.name !== fileName));
  };

  const onSubmit = (data: any) => {
    const blueTickRequest = {
      reason: data.reason,
      requestStatus: BlueTickVerified.NotComplete,
      
    }
    createRequest(blueTickRequest).then((response: any) => {
      console.log('Request created successfully');
      if(fileValue.length > 0){
        const formData = new FormData();
        fileValue.forEach(file => {
          formData.append('files', file);
        });
        const args = {id: response.data.id, files: formData};
        uploadAttachment(args).then(() => {
          toastSuccess(t('requestSentSuccessfully'));
        }).catch((error) => {
          console.error('Error uploading attachment:', error);
        });
      }
    }).catch((error) => {
      console.error('Error creating request:', error);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {/* Reason Field */}
      <div className="mb-4">
        <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
          Reason *
        </label>
        <textarea
          id="reason"
          {...register('reason')}
          className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
          placeholder="Provide your reason here..."
        ></textarea>
        {errors.reason && <span className="text-red-500 text-xs">{t(errors.reason.message as string)}</span>}
      </div>

      {/* Attachment Field */}
      <div className="my-6 items-center cursor-pointer">
        <label htmlFor="file-upload-contact" className="justify-center flex-1">
          <p className="text-sm font-medium text-gray-700">Add an official identification document of yourself</p>
          <div className="my-2 w-full flex justify-center p-2 border-2 border-dashed rounded-md cursor-pointer">
            <div className="space-y-1 text-center">
              <div id="input_file" className="flex w-full justify-center text-center text-xs text-gray-300 py-2">
                <div>
                  <input
                    type="file"
                    id="file-upload-contact"
                    data-testid="file-upload-contact"
                    accept=".png,.jpeg,.jpg"
                    className="hidden"
                    multiple
                    onChange={validateFormate}
                  />
                </div>
                <div className="flex items-center">
                  <ToolTip tip="Attachment should be in PNG, JPEG, or JPG format">
                    <div className="flex items-center">
                      <Icon icon="ADD" size="x-small" height="small" stroke="#010101" />
                      <span className="ml-2">Add Attachment</span>
                    </div>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>
        </label>

        {/* Display uploaded files */}
        {!clearVisibility && fileValue.length > 0 && (
          <div>
            {fileValue.map((file, index) => (
              <div key={index} className="inline-block mr-2 mb-2">
                {file && file.name && (
                  <Chip
                    data-testid={`fileName-${file.name}`}
                    onClickClose={() => removeFile(file.name)} // Remove file on click
                    isSelected={true}
                    key={index}
                    removeCatId={`btn_removeCat_${index}`}
                    value={file.name}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Note */}
      <p className="text-sm text-gray-600">
        <strong>Note:</strong> {t('faceVerificationShouldBeCompletedToGetVerifiedFurther')}
      </p>

      {/* Buttons */}
      <div className="flex justify-end items-center">
        <Button type="submit" submit>{t('confirm')}</Button>
      </div>
    </form>
  );
};

export const BlueTickVerificationModal = ({ isOpen, toggle }: any) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
      <ModalCloseButton toggle={toggle} />
      <ModalHeader>{t('requestForBlueTick')}</ModalHeader>
      <ModalBody>
        <BlueTickVerificationForm />
      </ModalBody>
    </Modal>
  );
};

export default BlueTickVerificationModal;
