/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { BlueTickRequest } from '../models/blueTickRequest.model';
import { allApis } from './allApis';

/**
 * Defines the endpoints for BlueTick request API.
 *
 * @param {object} options - Options for the API endpoints.
 * @return {{ createBlueTickRequest: mutation, uploadBlueTickAttachments: mutation, getAllBlueTickRequest: query }} - An object containing the defined endpoints.
 */

export const blueTickRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createBlueTickRequest: builder.mutation<BlueTickRequest, Partial<BlueTickRequest>>({
      query: (blueTickRequest) => ({
        url: 'blue-tick-request',
        method: 'POST',
        body: blueTickRequest,
      }),
    //   invalidatesTags: (result, error, blueTickRequest) => [
    //     { type: 'BlueTickRequest' },
    //   ],
    }),

    uploadBlueTickAttachments: builder.mutation<any, { id: string; files: FormData }>({
      query: ({ id, files }) => ({
        url: `blue-tick-request/upload/${id}`,
        method: 'POST',
        body: files,
      }),
    }),

    getAllBlueTickRequest: builder.query<BlueTickRequest[], void>({
      query: () => 'blue-tick-request',
    }),

    getBlueTickRequestById: builder.query<BlueTickRequest, string>({
      query: (id) => `blue-tick-request/user/${id}`,
    }),
  }),
});

export const {
  useCreateBlueTickRequestMutation,
  useUploadBlueTickAttachmentsMutation,
  useGetAllBlueTickRequestQuery,
  useGetBlueTickRequestByIdQuery,
} = blueTickRequestApi;
