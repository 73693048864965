/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */


import { User } from '../../../models/user.model'

  /**
   * Displays the name of a user based on the value of the `hostAnonymous`
   * prop. If `hostAnonymous` is `true`, the user's nickname is displayed.
   * If `hostAnonymous` is `false`, the user's first name and last name are
   * displayed. If `hostAnonymous` is not given, the user's nickname is
   * displayed if the user is anonymous, and the user's first name and last
   * name are displayed if the user is not anonymous.
   *
   * If `firstNameOnly` is `true`, the user's first name is displayed
   * regardless of the value of `hostAnonymous`.
   *
   * The displayed text is capitalized and up to 15 characters long,
   * followed by an ellipsis if it is longer than 15 characters.
   *
   * @param user The user whose name should be displayed.
   * @param hostAnonymous Whether to show the user's nickname or full name.
   * @param firstNameOnly Whether to show the user's first name only.
   * @returns A JSX element displaying the user's name.
   */
const DisplayName = ({ user, hostAnonymous, firstNameOnly = false }: { user: User, hostAnonymous?: boolean, firstNameOnly?: boolean }) => {

  return (
    <>
    <div className='capitalize text-lg'>
      {
        user && hostAnonymous === true && (user.anonymous ? user.nickName : '')
      }
      {
          user && hostAnonymous === false && (() => {
            const firstName = user.firstName || '';
            const lastName = !firstNameOnly ? (user.lastName || '') : '';
            const fullName = `${firstName} ${lastName}`.trim();

            if (fullName.length > 15) {
              return fullName.substring(0, 15) + '...';
            } else {
              return fullName;
            }
          })()}
       {
      user && hostAnonymous === undefined && (
        user.anonymous
          ? (user.nickName.length > 15 ? user.nickName.substring(0, 15) + '...' : user.nickName)
          : (user.firstName + ' ' + (!firstNameOnly ? (user.lastName ?? '') : '')).length > 15
          ? (user.firstName + ' ' + (user.lastName ?? '')).substring(0, 15) + '...'
          : user.firstName + ' ' + (user.lastName ?? '')
      )
    }
      </div>
    </>
  )
}
  /**
   * Returns the name of a user as a string, based on the value of the
   * `hostAnonymous` prop. If `hostAnonymous` is `true`, the user's
   * nickname is returned. If `hostAnonymous` is `false`, the user's first
   * name and last name are returned. If `hostAnonymous` is not given, the
   * user's nickname is returned if the user is anonymous, and the user's
   * first name and last name are returned if the user is not anonymous.
   *
   * @param user The user whose name should be returned as a string.
   * @param hostAnonymous Whether to return the user's nickname or full name.
   * @returns The user's name as a string, or undefined if `user` is not given.
   */
export const DisplayNameAsString = ({ user, hostAnonymous }: { user: User, hostAnonymous?: boolean }): (string | undefined) => {
  if (user && hostAnonymous === true && user.anonymous)
    return user.nickName
  else if (user && hostAnonymous === false)
    return user.firstName + ' ' + (user.lastName ?? '')
  else if (user && hostAnonymous === undefined) {
    if (user.anonymous)
      return user.nickName
    else
      return user.firstName + ' ' + (user.lastName ?? '')
  }
}
export default DisplayName




